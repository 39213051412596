import React, { Component, useState } from "react";
import colors from "../config/colors";
import "bootstrap/dist/css/bootstrap.css";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
// import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
// import Grid from "@mui/material/Grid";
import ChatRoundedIcon from "@mui/icons-material/ChatRounded";
import CallRoundedIcon from "@mui/icons-material/CallRounded";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import { Auth } from "aws-amplify";
import { BrowserRouter as Router, Link } from "react-router-dom";

import backgroundPic from "../assets/emily.svg";
// import altheaLogo from "../assets/Althea Logos High Res.png";
import altheaLogo from "../assets/logo-for-center-QR 2.png";
import altheaIcon from "../assets/Althea-tablogo-dark-d1.jpg";
import websiteIcon from "../assets/website_window_icon.svg";
import demoIcon from "../assets/speedometer-svgrepo-com_1.svg";
import signupIcon from "../assets/signup_icon.svg";
import demoVideoIcon from "../assets/movie-clapperboard-part-2-svgrepo-com.svg";
import joinusIcon from "../assets/programming_web_icon.svg";
// import phoneIcon from "../assets/phone-call-icon.svg";
import phoneIcon from "../assets/telephone-svgrepo-com.svg";

import facebookLogo from "../assets/facebook_logo.svg";
import instagramLogo from "../assets/instagram_logo.svg";
import twitterLogo from "../assets/twitter_logo.svg";
import linkedinLogo from "../assets/linkedin_logo.svg";
import youtubeLogo from "../assets/youtube_logo.svg";

import WaveBackground from "./waveBackground";

import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";

// import facebookLogo from "../assets/icons8-facebook.svg";
// import instagramLogo from "../assets/icons8-instagram.svg";
// import twitterLogo from "../assets/icons8-twitter.svg";
// import linkedinLogo from "../assets/icons8-linkedin.svg";
// import youtubeLogo from "../assets/icons8-youtube.svg";

// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: "center",
//   color: theme.palette.text.secondary,
// }));

// const altheaWebsite = "https://www.althea.health";
const altheaWebsite = "https://www.altheahealth.io";
const altheaWebsiteDemo = "https://www.altheahealth.io/live-demo";
const altheaEmailAddress = "info@althea.health";
// const altheaDemoSite =
// "https://demo.altheademo.com/user71b1aea3-b098-41ff-abf1-ab97fdf64e91";
const altheaDemoSite = "https://forms.gle/8zhACmVaTr74nFzt9";
const altheaSignup = "https://forms.gle/8zhACmVaTr74nFzt9";
const altheaDemoVideo = "https://www.youtube.com/watch?v=giYmrSEtas0&t=1s";
const altheaJoinus = "https://forms.gle/FazYeGNomMKokNAv6";
const altheaYoutube = "https://www.youtube.com/@altheahealth2720";
const altheaLinkedin = "https://www.linkedin.com/company/althea-health/";
const altheaTwitter = "https://twitter.com/altheahealthai";
const altheaFacebook = "";
const altheaInstagram = "";

const pcpScheduling = "https://www.youtube.com/embed/ZIB9r35BmPI";
const radiologyScheduling = "https://www.youtube.com/embed/PTFSpxbnIoE";
const medAdherenceDemoLink = "https://www.youtube.com/embed/oUtUIhDO4-M";
const gapcallDemoLink = "https://www.youtube.com/embed/E_Gb9Yp23IE";

const reminderDemoLink = "https://www.youtube.com/embed/UHt9nhviGS4";
const backfillingDemoLink = "https://www.youtube.com/embed/GPDO3RvSKOI";
const registrationDemoLink = "https://www.youtube.com/embed/eHRxCLjzV8c";

const medAdherenceDemoV3 = "https://www.youtube.com/embed/wzqoawogp5I";
const annualWellnessDemoV3 = "https://www.youtube.com/embed/aUt4bGhtC-8";
const bscScreeningDemoV3 = "https://www.youtube.com/embed/4PFQ8Qf2nw8";
const altheaWorkFlowDemoV1 = "https://www.youtube.com/embed/EYBjyssnJtw";
const medRefillDemoV3 = "https://www.youtube.com/embed/d-RaQ2T-l8Y";

// const schedulingDemoLink = "https://www.youtube.com/embed/orHNajgD_tg";
// const medAdherenceDemoLink = "https://www.youtube.com/embed/f-9eBNfoLqQ";
// const workflowAD = "https://www.youtube.com/embed/_NmSc3GzJy8";
// const workflowCCR = "https://www.youtube.com/embed/nqoyUmdRYqs";
// const gapcallDemoLink = "https://www.youtube.com/embed/mnZtXWCSH2E";
// const gapcallDemoLink = "https://www.youtube.com/embed/-b3Lf0lXoVI";

// const altheaPhoneNumber = "+18773785178";
const altheaPhoneNumber = "+19805258432";

// const videoWidth = window.innerWidth < 500 ? "200px" : "500px"; //"auto";
const videoWidth = "auto";
const videoHeight = window.innerWidth < 500 ? "200px" : "500px";

{
  /* <iframe width="935" height="526" src="https://www.youtube.com/embed/orHNajgD_tg" title="" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
<iframe width="935" height="526" src="https://www.youtube.com/embed/mnZtXWCSH2E" title="" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
<iframe
  width="935"
  height="526"
  src="https://www.youtube.com/embed/q1kWVpgap5k"
  title=""
  frameborder="0"
  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
  allowfullscreen
></iframe>; */
}

class HomeComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authStatus: null,
      user: null,
      userSub: null,
      userGroups: null,
      // isMobile: false,
      // windowWidth: window.innerWidth < 500 ? 500 : window.innerWidth,
      // windowHeight: window.innerHeight < 500 ? 500 : window.innerHeight,
      // userIP: null,
      isMobile: this.props.isMobile,
      buttonFontsize: window.innerWidth < 500 ? 11 : 16,
      xsItem: window.innerWidth < 500 ? 10 : 10,
      // xsSocial: window.innerWidth < 500 ? 3.5 : 2,
      xsSocial: window.innerWidth < 500 ? 3 : 3,
      videoWidth: window.innerWidth < 500 ? "300px" : "500px",
    };
    this.style = {
      page: {
        // position: "relative",
        width: "100%",
        height: "auto",
        display: "flex",
        flexDirection: "column",
        // alignItems: "center",
        // alignItems: "space-between",
        // alignItems: "flex-start",
        // justifyContent: "flex_start",
        alignItems: "center",
        justifyContent: "center",
        // borderStyle: "solid",
        // backgroundImage: `url(${backgroundPic})`,
      },
      header: {
        // width: window.innerWidth * 0.7,
        // height: window.innerHeight * 0.1,
        position: "relative",
        width: "100%",
        height: "20%",
        minHeight: "100px",
        boxShadow: 4,
        // backgroundColor: "green",
        // backgroundColor: colors.althea_grey_light,
        // backgroundColor: colors.althea_grey_light,
        borderColor: colors.althea_grey_dark_double,
        // borderWidth: 1,
        // borderStyle: "solid",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        // justifyContent: "flex-start",
        // alignItems: "center",
        // fontSize: 48,
        // color: colors.althea_main_dark,
        // color: colors.althea_grey_light,
        // fontFamily: "Nunito-semibold",
        // fontStyle: "bold",
        // borderTopLeftRadius: 10,
        // borderTopRightRadius: 10,
      },
      mainPanel: {
        // width: window.innerWidth * 0.7,
        // height: window.innerHeight * 0.6,
        position: "relative",
        width: "100%",
        // height: "100%",
        minHeight: "100px",
        // backgroundColor: colors.althea_blue,
        // borderColor: colors.althea_black,
        // borderWidth: 1,
        // borderStyle: "solid",
        // margin: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // borderBottomLeftRadius: 10,
        // borderBottomRightRadius: 10,
        // alignContent: "center",
        // borderTopLeftRadius: 10,
        // borderTopRightRadius: 10,
        // "&:hover": {
        //   backgroundColor: "primary.main",
        //   opacity: [0.9, 0.8, 0.7],
        // },
      },
      middlePanel: {
        width: "100%", //this.props.isMobile ? "100%" : "66%",
        height: "100%",
        // backgroundColor: colors.althea_grey_light,
        // borderColor: colors.althea_black,
        // borderWidth: 1,
        // borderStyle: "solid",
        // backgroundColor: "purple",
        display: "flex",
        position: "relative",
        justifyContent: "center",
        alignItems: "center",
      },
      gridItem: {
        position: "relative",
        display: "flex",
        width: "100%",
        // height: 1 ? "80%" : "auto",
        justifyContent: "center",
        alignItems: "center",
        // borderStyle: "solid",
        // backgroundColor: "red",
      },

      footer: {
        position: "relative",
        width: "auto",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // justifyContent: "center",
        // backgroundColor: colors.althea_grey,
        // borderBottomLeftRadius: 10,
        // borderBottomRightRadius: 10,
        // borderStyle: "solid",
      },
      socialIconPanel: {
        width: "100%", //this.props.isMobile ? "100%" : "66%",
        height: "80%",
        // backgroundColor: colors.althea_grey_light,
        // borderColor: colors.althea_black,
        // borderWidth: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        // fontFamily: "Nunito-semibold",
        // borderStyle: "solid",
      },
      linkButton: {
        fontFamily: "Nunito-semibold",
        fontSize: 16,
        textTransform: "none",
        // backgroundColor: colors.althea_main_dark,
      },

      gridIcon: {
        position: "relative",
        display: "flex",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "flex-start",
        // borderStyle: "solid",
      },
      socialMediaIconStack: {
        position: "relative",
        width: "100%",
        height: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // borderBottomLeftRadius: 10,
        // borderBottomRightRadius: 10,
      },
      gridSocialIcon: {
        position: "relative",
        // borderStyle: "solid",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // height: 0.075 * Math.min(window.innerHeight, window.innerWidth),
        // width: 0.075 * Math.min(window.innerHeight, window.innerWidth),
      },
      socialMediaIcon: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // fontSize: 18,
        // size: "2px",
        color: colors.althea_grey_light,
        // borderStyle: "solid",
        // height:
        //   0.1 *
        //   Math.min(this.props.windowDim.height, this.props.windowDim.width), //"100px",
        // width:
        //   0.1 *
        //   Math.min(this.props.windowDim.height, this.props.windowDim.width),
        // height: 0.1 * Math.min(this.state.windowHeight, this.state.windowWidth), //"100px",
        // width: 0.1 * Math.min(this.state.windowHeight, this.state.windowWidth),
        // height: 0.075 * Math.min(window.innerHeight, window.innerWidth),
        // width: 0.075 * Math.min(window.innerHeight, window.innerWidth),
        // transform: `scale(0.5, 0.5)`,
        width: "80%",
        height: "80%",
      },
    };
  }

  componentDidMount = async () => {
    // await this.getUserSub();
    window.addEventListener("resize", this.handleWindowSizeChange);
    this.handleWindowSizeChange();
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    const isMobile = window.innerWidth <= 768;
    // const minHeight = window.innerWidth <= 254;
    if (isMobile !== this.state.isMobile) {
      this.setState({ isMobile });
    }
    this.setState({
      // windowWidth: window.innerWidth < 500 ? 500 : window.innerWidth,
      // windowHeight: window.innerHeight < 500 ? 500 : window.innerHeight,
      // xsSocial:
      //   Math.min(this.props.windowDim.height, this.props.windowDim.width) / 400,
      buttonFontsize: window.innerWidth < 500 ? 11 : 16,
    });
  };

  openInNewTab = (url) => {
    // window.open(url, "_blank", "noreferrer");
    window.open(url, "_blank");
  };

  goToFacebook = () => {
    this.openInNewTab(altheaFacebook, "_blank").focus();
    // <a href={altheaSite} target="_blank"></a>;
  };
  goToLinkedin = () => {
    this.openInNewTab(altheaLinkedin, "_blank").focus();
  };
  goToInstagram = () => {
    this.openInNewTab(altheaInstagram, "_blank").focus();
  };
  goToTwitter = () => {
    this.openInNewTab(altheaTwitter, "_blank").focus();
  };
  goToYoutube = () => {
    this.openInNewTab(altheaYoutube, "_blank").focus();
  };

  render() {
    return (
      <Box sx={this.style.page}>
        {/* Header */}
        <Grid sx={this.style.header}>
          <Box
            sx={{
              marginLeft: "10%",
              // borderStyle: "solid",
              // marginLeft: "auto",
            }}
          >
            {/* <a href={altheaWebsite} target="_blank" className="logo-header"> */}
            <a href={altheaWebsite} target="_blank">
              {/* {"Welcome to ALTHEA!"} */}
              <img
                src={altheaLogo}
                className="logo-img"
                // loading="lazy"
                // alt="logo"
                style={
                  {
                    // width: "10vw",
                    // height: "10vh",
                    // resizeMode: "cover",
                    // borderStyle: "solid",
                    // transform: `scale(0.5, 0.5)`,
                    // marginLeft: "15%",
                  }
                }
              />
            </a>
          </Box>
          {/* <Box
            sx={{
              borderStyle: "solid",
              marginRight: "15%",
              height: "100%",
              width: "15%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
              // marginRight: "auto",
            }}
          > */}
          <ItemCompCall
            style={this.style}
            // itemText={"Call Althea"}
            // itemText={"Talk to Althea"}
            itemText={"Visit Our Website"}
            itemIcon={phoneIcon}
            // itemLink={altheaWebsiteDemo}
            itemLink={altheaWebsite}
            phoneNumber={altheaPhoneNumber}
          />
          {/* <Button>Hello</Button> */}
          {/* </Box> */}
        </Grid>
        {/* items and socials */}
        <Grid
          container
          spacing={10}
          sx={{
            // width: "100%",
            // position: "relative",
            // backgroundColor: "white",
            // height: "80%",
            // minHeight: "600px",
            display: "flex",
            // alignItems: "flex-start",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              // width: "100%",
              // height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // borderStyle: "solid",
              // backgroundColor: "green",
            }}
          >
            {/* Main links to other pages */}
            {/* <Box sx={this.style.mainPanel}>
              <Grid container spacing={2} sx={this.style.middlePanel}>
                <Grid
                  item
                  xs={this.state.xsItem}
                  sx={this.style.gridItem}
                ></Grid>
                <Grid item xs={this.state.xsItem} sx={this.style.gridItem}>
                  <ItemCompVideo
                    style={this.style}
                    itemText={"Visit Website"}
                    itemIcon={websiteIcon}
                    itemLink={altheaWebsite}
                    videoLink={workflowAD}
                  />
                </Grid>
                <Grid item xs={this.state.xsItem} sx={this.style.gridItem}>
                  <ItemCompVideo
                    style={this.style}
                    itemText={"Visit Website"}
                    itemIcon={websiteIcon}
                    itemLink={altheaWebsite}
                    videoLink={workflowCCR}
                  />
                </Grid>
              </Grid>
            </Box> */}
            <Box sx={this.style.mainPanel}>
              <Grid container spacing={2} sx={this.style.middlePanel}>
                <Grid
                  item
                  xs={this.state.xsItem}
                  sx={this.style.gridItem}
                ></Grid>
                <Grid item xs={this.state.xsItem} sx={this.style.gridItem}>
                  <ItemCompVideo
                    style={this.style}
                    itemText={"Visit Website"}
                    itemIcon={websiteIcon}
                    itemLink={altheaWebsite}
                    videoLink={medAdherenceDemoV3}
                  />
                </Grid>
                <Grid item xs={this.state.xsItem} sx={this.style.gridItem}>
                  <ItemCompVideo
                    style={this.style}
                    itemText={"Visit Website"}
                    itemIcon={websiteIcon}
                    itemLink={altheaWebsite}
                    videoLink={annualWellnessDemoV3}
                  />
                </Grid>
                <Grid item xs={this.state.xsItem} sx={this.style.gridItem}>
                  <ItemCompVideo
                    style={this.style}
                    itemText={"Visit Website"}
                    itemIcon={websiteIcon}
                    itemLink={altheaWebsite}
                    videoLink={bscScreeningDemoV3}
                  />
                </Grid>
                <Grid item xs={this.state.xsItem} sx={this.style.gridItem}>
                  <ItemCompVideo
                    style={this.style}
                    itemText={"Visit Website"}
                    itemIcon={websiteIcon}
                    itemLink={altheaWebsite}
                    videoLink={medRefillDemoV3}
                  />
                </Grid>
                <Grid item xs={this.state.xsItem} sx={this.style.gridItem}>
                  <ItemCompVideo
                    style={this.style}
                    itemText={"Visit Website"}
                    itemIcon={websiteIcon}
                    itemLink={altheaWebsite}
                    videoLink={pcpScheduling}
                  />
                </Grid>
                <Grid item xs={this.state.xsItem} sx={this.style.gridItem}>
                  <ItemCompVideo
                    style={this.style}
                    itemText={"Visit Website"}
                    itemIcon={websiteIcon}
                    itemLink={altheaWebsite}
                    videoLink={radiologyScheduling}
                  />
                </Grid>
                <Grid item xs={this.state.xsItem} sx={this.style.gridItem}>
                  <ItemCompVideo
                    style={this.style}
                    itemText={"Visit Website"}
                    itemIcon={websiteIcon}
                    itemLink={altheaWebsite}
                    videoLink={altheaWorkFlowDemoV1}
                  />
                </Grid>
                {/* <Grid item xs={this.state.xsItem} sx={this.style.gridItem}>
                  <ItemCompVideo
                    style={this.style}
                    itemText={"Visit Website"}
                    itemIcon={websiteIcon}
                    itemLink={altheaWebsite}
                    videoLink={workflowAD}
                  />
                </Grid>
                <Grid item xs={this.state.xsItem} sx={this.style.gridItem}>
                  <ItemCompVideo
                    style={this.style}
                    itemText={"Visit Website"}
                    itemIcon={websiteIcon}
                    itemLink={altheaWebsite}
                    videoLink={workflowCCR}
                  />
                </Grid>     
                <Grid item xs={this.state.xsItem} sx={this.style.gridItem}>
                  <ItemCompVideo
                    style={this.style}
                    itemText={"Visit Website"}
                    itemIcon={websiteIcon}
                    itemLink={altheaWebsite}
                    videoLink={schedulingDemoLink}
                  />
                </Grid> */}
                {/* <Grid item xs={this.state.xsItem} sx={this.style.gridItem}>
                  <ItemCompVideo
                    style={this.style}
                    itemText={"Visit Website"}
                    itemIcon={websiteIcon}
                    itemLink={altheaWebsite}
                    videoLink={registrationDemoLink}
                  />
                </Grid>
                <Grid item xs={this.state.xsItem} sx={this.style.gridItem}>
                  <ItemCompVideo
                    style={this.style}
                    itemText={"Visit Website"}
                    itemIcon={websiteIcon}
                    itemLink={altheaWebsite}
                    videoLink={reminderDemoLink}
                  />
                </Grid>
                <Grid item xs={this.state.xsItem} sx={this.style.gridItem}>
                  <ItemCompVideo
                    style={this.style}
                    itemText={"Visit Website"}
                    itemIcon={websiteIcon}
                    itemLink={altheaWebsite}
                    videoLink={backfillingDemoLink}
                  />
                </Grid> */}
              </Grid>
            </Box>
          </Grid>
          {/* social media icons */}
          <Grid
            item
            xs={10}
            sx={{
              // width: "100%",
              // position: "relative",
              height: "20%",
              minHeight: "120px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // borderStyle: "solid",
            }}
          >
            <Stack sx={this.style.footer}>
              <Typography sx={style.socialMediaText}>
                {"Connect with us"}
              </Typography>
              <Grid container spacing={0} sx={this.style.socialIconPanel}>
                <Grid item xs={this.state.xsSocial} sx={this.style.gridIcon}>
                  <IconComp
                    style={this.style}
                    itemIcon={altheaIcon}
                    itemLink={altheaWebsite}
                  />
                </Grid>
                <Grid item sx={this.style.gridIcon} xs={this.state.xsSocial}>
                  <IconComp
                    style={this.style}
                    itemIcon={linkedinLogo}
                    itemLink={altheaLinkedin}
                  />
                </Grid>
                <Grid item xs={this.state.xsSocial} sx={this.style.gridIcon}>
                  <IconComp
                    style={this.style}
                    itemIcon={youtubeLogo}
                    itemLink={altheaYoutube}
                  />
                </Grid>
                <Grid item xs={this.state.xsSocial} sx={this.style.gridIcon}>
                  <IconComp
                    style={this.style}
                    itemIcon={twitterLogo}
                    itemLink={altheaTwitter}
                  />
                </Grid>
              </Grid>
              <a href={`mailto:${altheaEmailAddress}`}>
                <Typography sx={style.contactText}>
                  {"info@althea.health"}
                </Typography>
              </a>
            </Stack>
          </Grid>
          {/* Wave pattern in the background */}
          <Grid
            sx={{
              position: "relative",
              width: "100%",
              height: "auto",
              // top: "-40px",
              // zIndex: -10,
              // borderWidth: 1,
              // borderStyle: "solid",
              // display: "flex",
              // alignContent: "flex-end",
              justifyContent: "center",
              alignItems: "center",
              // borderColor: "black",
              // backgroundColor: "red",
            }}
          >
            <WaveBackground />
          </Grid>
        </Grid>
      </Box>
    );
  }
}

export default HomeComponent;

class ItemComp extends Component {
  state = {};
  render() {
    return (
      <a href={this.props.itemLink} target="_blank">
        <Stack sx={style.linkPicStack}>
          <Box
            sx={{
              //borderStyle: "solid",
              width: "100%",
              height: "25px",
            }}
          />
          <IconButton aria-label="delete" size="large" sx={style.linkPicIcon}>
            <img
              src={this.props.itemIcon}
              // className="App-logo"
              // loading="lazy"
              // alt="logo"
              style={style.linkPicImage}
            />
          </IconButton>
          <Button
            sx={this.props.style.linkButton}
            // onClick={
            //   this.props.itemText === "Try Althea" ? signInDemo : () => {}
            // }
            variant="contained"
          >
            {this.props.itemText}
          </Button>
        </Stack>
      </a>
    );
  }
}

class ItemCompVideo extends Component {
  state = {};
  render() {
    return (
      // <a href={this.props.itemLink} target="_blank">
      // <Box>
      //   <Stack sx={style.linkPicStack}>
      //   <Box
      //     sx={{
      //       //borderStyle: "solid",
      //       width: "100%",
      //       height: "100%",
      //       // height: "auto",
      //     }}
      //   />
      // {/* <IconButton aria-label="delete" size="large" sx={style.linkPicIcon}>
      //     <img
      //       src={this.props.itemIcon}
      //       // className="App-logo"
      //       // loading="lazy"
      //       // alt="logo"
      //       style={style.linkPicImage}
      //     />
      //   </IconButton> */}
      // {/* <div className="video-container rounded">
      //     <iframe
      //       width="50%"
      //       height="50%"
      //       src="https://www.youtube.com/embed/5qa19rliVKE"
      //       title="Inbound patient call for scheduling"
      //       frameborder="0"
      //       allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      //       allowfullscreen
      //     ></iframe>
      //   </div> */}

      <Box
        sx={{
          // borderRadius: "150px",
          width: "100%",
          // height: "auto",
          // backgroundColor: "blue",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card
          sx={{
            borderRadius: "25px",
            width: "80%",
            // height: "100%",
            alignItems: "center",
            justifyContent: "center",
            // backgroundColor: "green",
          }}
        >
          <CardMedia
            component="iframe"
            title="Embedded YouTube Video"
            // height="315" // Adjust the height as needed
            // width="600"
            // height={
            //   window.innerWidth < 500
            //     ? window.innerHeight * 0.5
            //     : window.innerHeight * 0.25
            // }
            height={videoHeight}
            width={videoWidth}
            // height="500px"
            // width="100%"
            // height="100%"
            src={this.props.videoLink}
          />
        </Card>
      </Box>

      // {/* <Button
      //     sx={this.props.style.linkButton}
      //     // onClick={
      //     //   this.props.itemText === "Try Althea" ? signInDemo : () => {}
      //     // }
      //     variant="contained"
      //   >
      //     {this.props.itemText}
      //   </Button> */}
      // {/* </Stack> */}
      // {/* </a> */}

      // </Box>
    );
  }
}

class ItemCompCall extends Component {
  state = {};
  render() {
    return (
      // <a href={`tel:${this.props.phoneNumber}`} target="_blank">
      <Box
        sx={{
          // borderStyle: "solid",
          marginRight: "15%",
          height: "100%",
          width: "24%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // alignContent: "center",
          // backgroundColor: "red",
          // marginRight: "auto",
        }}
      >
        {/* <a href={`tel:${this.props.phoneNumber}`} target="_blank"> */}
        <Box
          sx={{
            borderRadius: 20,
            // borderStyle: "solid",
            // borderWidth: "0.1px",
            // borderColor: "#f2f2f2",
            width: "100%",
            minWidth: "200px",
            // height: "100%",
            backgroundColor: "#FFBC00",
            position: "relative",
            alignItems: "center",
            justifyContent: "space-evenly",
            // width: "100%",
            // height: "100%",
            // backgroundColor: "blue",
            boxShadow: 2,
          }}
        />
        {/* <Box
          sx={{
            borderRadius: 20,
            // borderStyle: "solid",
            // borderWidth: "0.1px",
            // borderColor: "#f2f2f2",
            width: "100%",
            minWidth: "200px",
            // height: "100%",
            backgroundColor: "#FFBC00",
            position: "relative",
            alignItems: "center",
            justifyContent: "space-evenly",
            // width: "100%",
            // height: "100%",
            // backgroundColor: "blue",
            boxShadow: 2,
          }}
        >
          <a href={`tel:${this.props.phoneNumber}`} className="call-style">
            <CallRoundedIcon
              sx={{
                // width: "100%",
                // height: "100%",
                color: "#000000",
                fontSize: 36,
                // borderRadius: "50px",
                fontWeight: "bold",
                // leftMargin: "10px",
                // borderStyle: "solid",
              }}
              //   fontSize="inherit"
              // style={
              //   this.state.enableCall
              //     ? {
              //         fontSize: this.props.isMobile
              //           ? window.innerWidth / 5
              //           : 160,
              //         color: colors.althea_green,
              //       }
              //     : {
              //         fontSize: this.props.isMobile
              //           ? window.innerWidth / 5
              //           : 160,
              //         color: colors.althea_grey_dark,
              //       }
              // }
              // disabled
            />
            <Button
              sx={{
                fontFamily: "Nunito-bold",
                fontSize: 18,
                textTransform: "none",
                color: "#000000",
                // fontWeight: "bold",
                // borderStyle: "solid",
                // backgroundColor: colors.althea_main_dark,
              }}
              // disableElevation
              // onClick={
              //   this.props.itemText === "Try Althea" ? signInDemo : () => {}
              // }
              // variant="contained"
            >
              {this.props.itemText}
            </Button>
          </a>
        </Box> */}
        <Box
          sx={{
            borderRadius: 20,
            // borderStyle: "solid",
            // borderWidth: "0.1px",
            // borderColor: "#f2f2f2",
            width: "100%",
            minWidth: "200px",
            // height: "100%",
            backgroundColor: "#FFBC00",
            position: "relative",
            alignItems: "center",
            justifyContent: "space-evenly",
            // width: "100%",
            // height: "100%",
            // backgroundColor: "blue",
            boxShadow: 2,
          }}
        >
          <a
            href={this.props.itemLink}
            target="_blank"
            rel="noopener noreferrer"
            className="call-style"
          >
            <Button
              sx={{
                fontFamily: "Nunito-bold",
                fontSize: 18,
                textTransform: "none",
                color: "#000000",
                // fontWeight: "bold",
                // borderStyle: "solid",
                // backgroundColor: colors.althea_main_dark,
              }}
              // disableElevation
              // onClick={
              //   this.props.itemText === "Try Althea" ? signInDemo : () => {}
              // }
              // variant="contained"
            >
              {this.props.itemText}
            </Button>
          </a>
        </Box>
      </Box>
      // </a>
    );
  }
}

class IconComp extends Component {
  state = {};
  render() {
    return (
      <a href={this.props.itemLink} target="_blank">
        <Stack sx={style.linkPicStack}>
          <IconButton aria-label="delete" size="large" sx={style.linkPicIcon}>
            <img
              src={this.props.itemIcon}
              // className="App-logo"
              // loading="lazy"
              // alt="logo"
              style={style.linkSocialLogo}
            />
          </IconButton>
        </Stack>
      </a>
    );
  }
}

const style = {
  linkPicStack: {
    position: "relative",
    width: "100%",
    height: "100%",
    // aspectRatio: "1/1",
    display: "flex-column",
    justifyContent: "center",
    alignItems: "center",
    // borderStyle: "solid",
  },
  linkPicIcon: {
    position: "relative",
    // fontSize: 16,
    color: colors.althea_grey_light,
    // borderStyle: "solid",
    // height: "100%", //"250px",
    // width: "100%", // "250px",
  },
  linkPicIconCall: {
    position: "relative",
    // fontSize: 16,
    color: colors.althea_grey_light,
    // borderStyle: "solid",
    // height: "100%", //"250px",
    // width: "100%", // "250px",
  },
  linkPicImage: {
    width: "150px",
    height: "150px",
    // transform: `scale(1, 1)`,
  },
  linkSocialLogo: {
    // width: "80%", //"200px",
    // height: "80%", //"200px",
    width: "50px",
    height: "50px",
    borderRadius: 50,
    // transform: `scale(1, 1)`,
  },

  socialMediaText: {
    fontSize: 20,
    fontFamily: "Nunito-semibold",
    color: colors.althea_grey_dark_double,
    width: "100%",
    height: "30%",
    // height: 30,
    // marginLeft: 0.5,
    // marginRight: "2%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // borderStyle: "solid",
  },
  contactText: {
    fontSize: 16,
    fontFamily: "Nunito-semibold",
    color: colors.althea_main_dark,
    width: "100%",
    height: "30%",
    // height: 30,
    // marginLeft: 0.5,
    // marginRight: "2%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // borderStyle: "solid",
  },
};

// class onMouseEnter extends Component {
//   constructor(props) {
//     super(props);
//   }
//   state = {};
//   render() {
//     return (
//       <Box>
//         <text>my script</text>
//       </Box>
//     );
//   }
// }
