import React, { Component, useState } from "react";
import "../App.css";

class WaveBackground extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <div className="wave-body">
        <svg
          className="wave-bckgnd"
          xmlns="http://www.w3.org/2000/svg"
          xlink="http://www.w3.org/1999/xlink"
          viewBox="0 24 150 28"
          preserveAspectRatio="none"
          shapeRendering="auto"
        >
          {/* <defs> */}
          <linearGradient id="myGradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#0a95b0" />
            <stop offset="100%" stopColor="#0db7c4" />
          </linearGradient>

          <path
            // id={id}
            // d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
            d="M-100 42c30 10 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
            // fill="#0a95b0"
            fill="url(#myGradient)"
          />
          {/* </defs> */}
        </svg>
      </div>
    );
  }
}

export default WaveBackground;
