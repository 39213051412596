export default {
  primary: "#fc5c65",
  secondary: "#0000",
  third: "orange",
  fourth: "blue",
  althea_main: "#0db7c4",
  althea_main_light: "#a7e5e9",
  althea_main_dark: "#0a95b0",
  althea_blue: "#20a4f3",
  althea_red: "#f24745",
  althea_green: "#a9d039",
  althea_yellow: "#ffbc00",
  althea_orange: "#ec6437",
  althea_grey_light: "#f6f6f6",
  althea_grey: "#f2f2f2",
  althea_grey_medium: "#dadce0",
  althea_grey_dark: "#b3b3b3",
  althea_grey_dark_double: "#595959",
  althea_white: "white",
  althea_black: "black",
  althea_red_opec: "#fbd9d3",
  althea_green_opec: "#dcedc9",
  // althea_blue_light: "#efe7ff",
  althea_blue_light: "#a4c2f4",
  althea_blue_dark: "#069af2",
};
