import logo from "./logo.svg";
import "./App.css";
import React, { Component, useEffect } from "react";
import "@aws-amplify/ui-react/styles.css";
import colors from "./config/colors";
import Box from "@mui/material/Box";
import HomeComponent from "./components/homeComponent";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import axios from "axios";
// import { Auth } from "aws-amplify";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authStatus: null,
      user: null,
      userSub: null,
      userGroups: null,
      isMobile: false,
      width: window.innerWidth,
      height: window.innerHeight,
      userIP: null,
    };
  }

  getUserGroup = async () => {
    let authStatus = false;
    this.getIP();
    return authStatus;
  };

  getIP = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    this.setState({ userIP: res.data.IPv4 });
  };

  componentDidMount = async () => {
    this.getUserGroup();
    await this.getIP();
    window.addEventListener("resize", this.handleWindowSizeChange);
    this.handleWindowSizeChange();
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    const isMobile = window.innerWidth <= 768;
    // const minHeight = window.innerWidth <= 254;
    if (isMobile !== this.state.isMobile) {
      this.setState({ isMobile });
    }
    this.setState({
      // windowWidth: window.innerWidth < 500 ? 500 : window.innerWidth,
      // windowHeight: window.innerHeight < 500 ? 500 : window.innerHeight,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    });
  };

  render() {
    // console.log(window.innerHeight, window.innerWidth);
    return (
      <Box
        // className="body"
        sx={{
          position: "relative",
          // width: this.state.windowWidth,
          // height: this.state.windowHeight,
          // display: "flex",
          // justifyContent: "center",
          // alignItems: "center",
          backgroundColor: colors.althea_white,
          // borderStyle: "solid",
          // borderColor: " red",
          // borderWidth: 1,
        }}
      >
        <Router>
          <Routes>
            <Route
              exact
              path="/"
              element={
                <HomeComponent
                  // signOut={signOut}
                  // user={user}
                  isMobile={this.state.isMobile}
                  windowDim={{
                    height: window.innerHeight,
                    width: window.innerWidth,
                  }}
                />
              }
            />
          </Routes>
        </Router>
      </Box>
    );
  }
}

export default App;
